<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="c-bg-gray">
      <v-toolbar dark color="primary">
        <v-btn
          icon
          dark
          @click="dialog = false"
          :to="{ name: 'CarRentalVendor' }"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Vehicles</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>
        <v-card class="tw-mt-4">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="my-8"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="vehicleData"
            :search="search"
          >
          </v-data-table>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Make',
          align: 'start',
          sortable: false,
          value: 'make',
        },
        { text: 'Model', value: 'model' },
        { text: 'YOM', value: 'yom' },
        { text: 'Engine cc', value: 'engine_cc' },
        { text: 'Reg No', value: 'registration_number' },
        { text: 'Status', value: 'vendor_vehicle.vehicle_status' },
      ],
    }
  },

  computed: {
    vehicleItem() {
      return this.scanObject(this.$route, 'query.vehicle')
        ? JSON.parse(this.$route.query.vehicle)
        : {}
    },

    vehicleData() {
      return this.scanObject(this.vehicleItem, 'vehicles')
    },
  },
}
</script>
